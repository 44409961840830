.preimg{
  background: url('https://res.cloudinary.com/cybertech13/image/upload/v1632845860/Bounce-Bar-Preloader-1_cdsstd.gif') center;
  width: 100vw;
  height: 100vh;
}
.error404{
  margin-top: 125px;
}
.error404 img{
  margin-left: 20vw;
  width: 50vw;
  height: 60vh;
}
.nav img{
  margin-top: 0px;
}
.nav a{
  margin-top: 0px;
  color: #fff;
}
.logo{
  display: none;
}
@media only screen and (max-width:800px){
  .error404 img{
    margin-left: 0vw;
    width: 100vw;
    height: auto;
  }
}